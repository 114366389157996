.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  padding-top: 15px;

font-family: Quicksand;

background-color:whitesmoke;


  

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top-center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.pointer         { cursor: pointer; }
















.card {
width: 360px;




background-color: white;





padding: 15px;
border-radius: 15px;
align-content:center;
text-align: start;

font-size:small;
font-weight: normal;

}

.card h1 {
  font-size: small;
  color:rgb(73, 73, 73);

  font-weight:750;



}

.btn {
width: 330px;
height: 50px;
background-color: #0d6EFD;
color:whitesmoke;
border-radius: 10px;
font-weight: bold;
border-color: transparent;

}

.btn-pay {
  width: 330px;
  height: 50px;
  background-color: green;
  color:whitesmoke;
  border-radius: 10px;
  font-weight: bold;
  border-color: transparent;
font-size: medium;
}
  

  .btn-pay:hover {

    width: 330px;
    height: 50px;
    background-color: rgb(2, 104, 2);
    color:whitesmoke;
    border-radius: 10px;
    font-weight: bold;
    border-color: transparent;
  font-size: medium;
  
  }
  



.btn:hover {

  width: 330px;
  height: 50px;
  
  background-color: #2579f8;
  color:whitesmoke;
  border-radius: 10px;
  font-weight: bold;
  border-color: transparent;

}

.img-fluid-update:hover {

  cursor: pointer;
  
}  




.form-control {

  color:rgb(73, 73, 73);
font-weight: 750;

background-color: rgb(214, 213, 213);

border-color: transparent;
border-radius: 5px;
font-size: small;
border-style: inset;
}


.form-control:disabled {

  color:rgb(73, 73, 73);
font-weight: 750;




  background-color: rgb(214, 213, 213);

border-color: transparent;
border-radius: 5px;
font-size: small;
border-style: inset;
  }
  





.table {

border-color: grey;  


font-size:small;
font-weight:normal;

}

